import React from 'react'
import { graphql } from 'gatsby'
import ReactMarkdown from 'react-markdown'
import Layout from '../components/layout'
import Seo from '../components/seo'
import Heading from '@atoms/Heading'
import Tail404 from '@images/svg/tail-404.svg'
import HeroTail from '@svg/hero-tail.svg'

const PrivacyPolicyPage = ({ data: { strapiPrivacyPolicyPage } }) => (
  <div className='flex flex-col justify-between min-h-screen page-404'>
    <Layout headerBg='bg-secondary'>
      <Seo title="Tenko App | Privacy Policy" />
      <section className='bg-secondary pt-4 pb-16 md:pt-24 md:pb-24 relative flex-grow'>
        <div className='max-w-screen-2xl mx-auto px-4 xl:pl-24'>
          <div className='flex flex-wrap items-center -mx-4'>
            <div className='w-full md:w-3/4 px-4'>
              <Heading variant='h1' color='white'>{strapiPrivacyPolicyPage.title}</Heading>
            </div>
          </div>
        </div>
        <Tail404 className='absolute bottom-0 left-0 w-full block pointer-events-none' />
        <HeroTail className='hidden md:block absolute z-10 misc2-tail' />
      </section>
      <section className='py-12'>
        <div className='max-w-screen-xl mx-auto px-6 md:px-4'>
          <div className='prose max-w-none text-entry'>
            <ReactMarkdown children={strapiPrivacyPolicyPage.content} />
          </div>
        </div>
      </section>
    </Layout>
  </div>
)

export default PrivacyPolicyPage


export const query = graphql`
  query {
    strapiPrivacyPolicyPage {
      title
      content
    }
  }
`;